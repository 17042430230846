import axios from './axios';

export default {
  list: () => axios.get('/local_its'),
  delete: (id) => axios.delete(`/local_its/${id}`),
  update: (id, payload) => axios.put(`/local_its/${id}`, { local_it: payload }),
  createCustomItOperateur: (id) => axios.post(`/local_its/${id}/create_custom_it_operateur`),
  create: () => axios.post('/local_its'),
  types: () => axios.get('/local_its/types'),
  enums: () => axios.get('/local_its/enums'),
  uploadServerList: (localItId, payload) => {
    axios.post(
      `/local_its/${localItId}/upload_liste_serveurs`,
      payload, {
        headers: {
          "content-type": "application/x-www-form-urlencoded"
        },
      }
    )
  },
};
