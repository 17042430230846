import axios from './axios';

export default {
  get: () => axios.get('/nouveau_service_attendu'),
  list: () => axios.get('/nouveau_service_attendus'),
  nouveauServiceAttendus: () => axios.get('/nouveau_service_attendus/liste_nouveau_service_attendus'),
  update: (params) => axios.put('/nouveau_service_attendu', {
    nouveau_service_attendu: params,
  }),
};
