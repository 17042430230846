import Vue from 'vue'
import VueRouter from 'vue-router'

import profile from './routes/profile'
import ressources from './routes/ressources'
import needs from './routes/needs'
import pages from './routes/pages'
import strategy from './routes/strategy'
import dashboardAndResults from './routes/dashboardAndResults'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    { path: '/', redirect: { name: 'dashboard' } },
    ...dashboardAndResults,
    ...pages,
    ...profile,
    ...ressources,
    ...needs,
    ...strategy,
    { path: '*', redirect: 'error-404' },
  ]
})

// TODO: Handle Route Before Each => Check if User Is LoggedIn

export default router
