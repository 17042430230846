/**
 * Concerns pages that are directly linked to business needs.
 * List of the routes:
 * - Access to Login page: /login
 * - Access to Not Found page: /error-404
 */
export default [
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/authentication/Login.vue'),
    meta: {
      layout: 'wrapper',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('@/views/authentication/ForgotPassword.vue'),
    meta: {
      layout: 'wrapper',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('@/views/authentication/ResetPassword.vue'),
    meta: {
      layout: 'wrapper',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/NotFound.vue'),
    meta: {
      layout: 'full'
    }
  },
  {
    path: '/introduction',
    name: 'introduction',
    component: () => import('@/views/Introduction.vue'),
    meta: {
      layout: 'wrapper',
    },
  }
]