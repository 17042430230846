import axios from './axios';

export default {
  get: () => axios.get('/entite'),
  entites: () => axios.get('/entites'),
  list: () => axios.get('/entites'),
  esrList: () => axios.get('/entites/esr'),
  secteurActivites: () => axios.get('/entites/secteur_activites'),
  entitesCreatedByFront: () => axios.get('/entites/entites_created_by_front'),
  createEntitesCreatedByFront: () => axios.post('entites/create_entite_by_front'),
  delete: (id) => axios.delete(`/entites/${id}`),
  update: (id, payload) => axios.put(`/entites/${id}`, { entite: payload }),
  resultDataAnnuel: () => axios.get('/entites/result_data_annuel'),
  resultDataParBaie: () => axios.get('/entites/result_data_par_baie'),
  resultDataParEtudiant: () => axios.get('/entites/result_data_par_etudiant'),
  summaryDocFull: () => axios.get('/entites/summary_document_pdf'),
  summaryDocSimplified: () => axios.get('entites/summary_document_simplified_pdf'),
  summaryDocDigest: () => axios.get('entites/summary_document_digest_pdf'),
};
