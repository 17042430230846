import { getField, updateField } from 'vuex-map-fields';
import SessionApi from '../../backend/SessionApi';
import UserApi from '../../backend/UserApi';
import axios from '../../backend/axios';
import { assignHelper } from '../helpers';

const initialUser = {
  prenom: '',
  nom: '',
  email: '',
  telephone: '',
  entite_id: undefined,
  fonction: '',
  droit_modif_donnees: null,
  commentaire: '',
  entite: {
    datacenter: {},
  },
};

const initialState = () => ({
  signInForm: {
    email: '',
    password: '',
    userType: '',
  },
  passwordResetForm: {
    email: '',
    password: '',
  },
  passwordChangeForm: {
    password: '',
    currentPassword: '',
  },
  user: initialUser,
});

export default {
  namespaced: true,
  mutations: {
    updateField,
    resetStore: (state) => {
      Object.assign(state, initialState());
    },
    setUser: assignHelper('user'),
    setSignUpForm: assignHelper('signUpForm'),
    setSignInForm: assignHelper('signInForm'),
    setPasswordResetForm: assignHelper('passwordResetForm'),
  },
  state: initialState(),
  actions: {
    async fetchMe({ commit }) {
      const response = await SessionApi.me();
      commit('setUser', response.data);
    },
    async signIn({ state, dispatch }) {
      delete localStorage.authorization;
      axios.defaults.headers.authorization = null;
      let response;
      response = await SessionApi.signIn({
        email: state.signInForm.email,
        password: state.signInForm.password,
      });
      localStorage.authorization = response.headers.authorization;
      axios.defaults.headers.authorization = response.headers.authorization;
      dispatch('fetchMe');
      return response;
    },
    async requestPasswordReset({ state, commit }) {
      const response = await SessionApi.requestPasswordReset({
        email: state.passwordResetForm.email,
      });
      commit('setPasswordResetForm', {
        email: '',
        password: '',
      });
      return response;
    },
    async updatePassword({ state }, token) {
      return await SessionApi.updatePassword({
        password: state.passwordResetForm.password,
        token,
      });
    },
    async updateMe({ commit, state }) {
      let response;
      response = await UserApi.updateMe(state.user, state.user.fund);
      commit('setUser', response.data);
    },
    async updateWithPassword({ state }) {
      await UserApi.updateWithPassword(state.passwordChangeForm);
    },
    async signOut({ dispatch }) {
      delete localStorage.authorization;
      dispatch('resetStore', null, { root: true });
      await SessionApi.signOut();
    },
  },
  getters: {
    getField,
    datacenterInvalid({ user }) {
      return !user.entite.datacenter.validated_at;
    },
  },
};
