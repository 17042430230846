import axios from './axios';

export default {
  get: () => axios.get('/service_hebergements'),
  listTypeHebergement: () => axios.get('/service_hebergements/liste_type_hebergement'),
  list: () => axios.get('/service_hebergements'),
  create: () => axios.post('/service_hebergements'),
  delete: (id) => axios.delete(`/service_hebergements/${id}`),
  update: (id, params) => axios.put(`/service_hebergements/${id}`, {
    id,
    service_hebergement: params,
  }),
};
