/**
 * Concerns the Needs Page.
 * This route is working with NESTED routes
 * - Access to Macro View page: /needs/macro-view
 * - Access to Applicative Function page: /needs/applicative-function
 * - Access to Mutualized Datacenter page: /needs/mutualized-datacenter
 */
export default [
  {
    path: '/needs',
    name: 'needs',
    component: () => import('@/views/needs/TheNeeds.vue'),
    meta: {
      layout: 'default',
    },
    children: [
      {
        path: 'security-referential',
        name: 'security-referential',
        component: () => import('@/views/needs/SecurityReferential.vue')
      },
      {
        path: 'macro-view',
        name: 'macro-view',
        component: () => import('@/views/needs/MacroView.vue')
      },
      /*
      {
        path: 'applicative-function',
        name: 'applicative-function',
        component: () => import('@/views/needs/ApplicativeFunction.vue')
      },
      */
      {
        path: 'mutualized-datacenter',
        name: 'mutualized-datacenter',
        component: () => import('@/views/needs/MutualizedDatacenter.vue')
      }
    ]
  },
]