/**
 * Concerns the Ressources Page.
 * This route is working with NESTED routes
 * - Access to Current Host page: /ressources/current-host
 * - Access to Host page: /ressources/host
 * - Access to Site Definition page: /ressources/site-definition
 */
export default [
  {
    path: '/ressources',
    name: 'ressources',
    component: () => import('@/views/ressources/TheRessources.vue'),
    meta: {
      layout: 'default',
    },
    children: [
      {
        path: 'current-host',
        name: 'current-host',
        component: () => import('@/views/ressources/CurrentHost.vue')
      },
      {
        path: 'host',
        name: 'host',
        component: () => import('@/views/ressources/Host.vue')
      },
      {
        path: 'site-definition',
        name: 'site-definition',
        component: () => import('@/views/ressources/SiteDefinition.vue')
      }
    ]
  }
]