import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

/*
 * Icons Custom
 */
import DashboardLight from "@/components/icons/DashboardLight.vue";
import Dashboard from "@/components/icons/Dashboard.vue";
import Result from "@/components/icons/Result.vue";
import ResultLight from "@/components/icons/ResultLight.vue";
import Warning from "@/components/icons/Warning.vue";
import CircleWarning from "@/components/icons/CircleWarning.vue";
import CircleCross from "@/components/icons/CircleCross.vue";
import CircleCheck from "@/components/icons/CircleCheck.vue";
import StepOneWhite from "@/components/icons/StepOneWhite.vue";
import StepOneLight from "@/components/icons/StepOneLight.vue";
import StepOnePurple from "@/components/icons/StepOnePurple.vue";
import StepTwoWhite from "@/components/icons/StepTwoWhite.vue";
import StepTwoLight from "@/components/icons/StepTwoLight.vue";
import StepTwoPurple from "@/components/icons/StepTwoPurple.vue";
import StepThreeWhite from "@/components/icons/StepThreeWhite.vue";
import StepThreeLight from "@/components/icons/StepThreeLight.vue";
import StepThreePurple from "@/components/icons/StepThreePurple.vue";
import StepFourWhite from "@/components/icons/StepFourWhite.vue";
import StepFourLight from "@/components/icons/StepFourLight.vue";
import StepFourPurple from "@/components/icons/StepFourPurple.vue";
import StepFiveWhite from "@/components/icons/StepFiveWhite.vue";
import StepFiveLight from "@/components/icons/StepFiveLight.vue";
import StepFivePurple from "@/components/icons/StepFivePurple.vue";
import ResultWhite from "@/components/icons/ResultWhite.vue";
import SavePurple from "@/components/icons/SavePurple.vue";
import SaveLight from "@/components/icons/SaveLight.vue";
import SaveWhite from "@/components/icons/SaveWhite.vue";
import CircleHelp from "@/components/icons/CircleHelp.vue";
import CircleAlphabetLetterOrange from "@/components/icons/CircleAlphabetLetterOrange.vue";
import CircleAlphabetLetterPurple from "@/components/icons/CircleAlphabetLetterPurple.vue";
import CircleAlphabetLetterRed from "@/components/icons/CircleAlphabetLetterRed.vue";
import NoUserAccount from "@/components/icons/NoUserAccount.vue";
import PasswordLost from "@/components/icons/PasswordLost.vue";
import UserAccountPurple from "@/components/icons/UserAccountPurple.vue";
import UserAccountWhite from "@/components/icons/UserAccountWhite.vue";
import ArrowRight from "@/components/icons/ArrowRight.vue";
import TrashCan from "@/components/icons/TrashCan.vue";
import Additionnal from "@/components/icons/Additionnal.vue";
import MinusWhite from "@/components/icons/MinusWhite.vue";
import PlusWhite from "@/components/icons/PlusWhite.vue";
import Disconnection from "@/components/icons/Disconnection.vue";
import TrashPurple from "@/components/icons/TrashPurple.vue";
import Edit from "@/components/icons/Edit.vue";
import Success from "@/components/icons/Success.vue";
import BasicArrow from "@/components/icons/BasicArrow.vue";
import WarningPurple from "@/components/icons/WarningPurple.vue"
import DatacenterPurple from "@/components/icons/DatacenterPurple.vue"
import DatacenterWhite from "@/components/icons/DatacenterWhite.vue";
import DatacenterBlue from "@/components/icons/DatacenterBlue.vue"
import DatacenterOrange from "@/components/icons/DatacenterOrange.vue"
import LocalBlue from "@/components/icons/LocalBlue.vue"
import LocalOrange from "@/components/icons/LocalOrange"
import CheckOrange from "@/components/icons/CheckOrange.vue"
import CheckPurple from "@/components/icons/CheckPurple.vue"
import CircleArrowGreen from "@/components/icons/CircleArrowGreen.vue"
import CircleArrowPurple from "@/components/icons/CircleArrowPurple.vue"
import FlashWhite from "@/components/icons/FlashWhite.vue"
import FlashOrange from "@/components/icons/FlashOrange.vue"
import EcoElec from "@/components/icons/EcoElec.vue"
import EcoElecPurple from "@/components/icons/EcoElecPurple"
import Help from "@/components/icons/Help.vue"
import SchoolBlue from "@/components/icons/SchoolBlue.vue"
import SchoolPurple from "@/components/icons/SchoolPurple.vue"
import EcoElecLight from "@/components/icons/EcoElecLight.vue"

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    values: {
      dashboardLight: {
        component: DashboardLight,
      },
      dashboard: {
        component: Dashboard,
      },
      noUserAccount: {
        component: NoUserAccount,
      },
      userAccountPurple: {
        component: UserAccountPurple,
      },
      userAccountWhite: {
        component: UserAccountWhite,
      },
      passwordLost: {
        component: PasswordLost,
      },
      warning: {
        component: Warning,
      },
      result: {
        component: Result,
      },
      resultLight: {
        component: ResultLight,
      },
      resultWhite: {
        component: ResultWhite,
      },
      circleWarning: {
        component: CircleWarning,
      },
      circleCheck: {
        component: CircleCheck,
      },
      circleHelp: {
        component: CircleHelp,
      },
      circleCross: {
        component: CircleCross,
      },
      stepOneWhite: {
        component: StepOneWhite,
      },
      stepOneLight: {
        component: StepOneLight,
      },
      stepOnePurple: {
        component: StepOnePurple,
      },
      stepTwoWhite: {
        component: StepTwoWhite,
      },
      stepTwoLight: {
        component: StepTwoLight,
      },
      stepTwoPurple: {
        component: StepTwoPurple,
      },
      stepThreeWhite: {
        component: StepThreeWhite,
      },
      stepThreeLight: {
        component: StepThreeLight,
      },
      stepThreePurple: {
        component: StepThreePurple,
      },
      stepFourWhite: {
        component: StepFourWhite,
      },
      stepFourLight: {
        component: StepFourLight,
      },
      stepFourPurple: {
        component: StepFourPurple,
      },
      stepFiveWhite: {
        component: StepFiveWhite,
      },
      stepFiveLight: {
        component: StepFiveLight,
      },
      stepFivePurple: {
        component: StepFivePurple,
      },
      savePurple: {
        component: SavePurple,
      },
      saveWhite: {
        component: SaveWhite,
      },
      saveLight: {
        component: SaveLight,
      },
      circleAlphabetLetterOrange: {
        component: CircleAlphabetLetterOrange,
      },
      circleAlphabetLetterPurple: {
        component: CircleAlphabetLetterPurple,
      },
      circleAlphabetLetterRed: {
        component: CircleAlphabetLetterRed,
      },
      arrowRight: {
        component: ArrowRight,
      },
      trashCan: {
        component: TrashCan,
      },
      additionnal: {
        component: Additionnal
      },
      minusWhite: {
        component: MinusWhite
      },
      plusWhite: {
        component: PlusWhite
      },
      disconnection: {
        component: Disconnection
      },
      trashPurple: {
        component: TrashPurple
      },
      edit: {
        component: Edit
      },
      success: {
        component: Success
      },
      basicArrow: {
        component: BasicArrow,
      },
      warningPurple: {
        component: WarningPurple,
      },
      datacenterPurple: {
        component: DatacenterPurple
      },
      datacenterWhite: {
        component: DatacenterWhite,
      },
      datacenterBlue: {
        component: DatacenterBlue,
      },
      datacenterOrange: {
        component: DatacenterOrange,
      },
      localBlue: {
        component: LocalBlue,
      },
      localOrange: {
        component: LocalOrange,
      },
      checkOrange: {
        component: CheckOrange
      },
      checkPurple: {
        component: CheckPurple
      },
      circleArrowGreen: {
        component: CircleArrowGreen,
      },
      circleArrowPurple: {
        component: CircleArrowPurple,
      },
      flashWhite: {
        component: FlashWhite,
      },
      flashOrange: {
        component: FlashOrange,
      },
      ecoElec: {
        component:EcoElec,
      },
      ecoElecPurple: {
        component:EcoElecPurple,
      },
      help: {
        component: Help,
      },
      schoolBlue: {
        component: SchoolBlue,
      },
      schoolPurple: {
        component: SchoolPurple,
      },
      ecoElecLight: {
        component: EcoElecLight
      }
    },
  },
  theme: {
    themes: {
      light: {
        success: '#48a9a6',
        error: '#d52941',
      },
    }
  }
});
