import axios from 'axios';
import { VUE_APP_API_URL } from '../constants';

const axiosConfig = {
  baseURL: VUE_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
};

if (localStorage.authorization) {
  axiosConfig.headers.authorization = localStorage.authorization;
}

export default axios.create(axiosConfig);
