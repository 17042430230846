import Vue from 'vue'
import Vuex from 'vuex'
import { getField, updateField } from 'vuex-map-fields';
import stepper from './modules/stepper'
import authentication from './modules/authentication'
import form from './modules/form'
import { assignHelper } from './helpers';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    stepper,
    authentication,
    form
  },
  state: {
    alert: {
      show: false,
      text: '',
    },
  },
  getters: {
    getField,
  },
  mutations: {
    setAlert: assignHelper('alert'),
    updateField,
  },
  actions: {
    resetStore({ commit }) {
      commit('authentication/resetStore');
      commit('form/resetStore');
      commit('stepper/resetStore');
    },
  },
})
