/**
 * Concerns the Strategy Page.
 * This route is working with NESTED routes
 * - Access to Impact page: /strategy/impact
 * - Access to Host page: /strategy/long-terme
 */
 export default [
  {
    path: '/strategy',
    name: 'strategy',
    component: () => import('@/views/strategy/TheStrategy.vue'),
    meta: {
      layout: 'default',
    },
    children: [
      {
        path: 'impact',
        name: 'impact',
        component: () => import('@/views/strategy/Impact.vue')
      },
      {
        path: 'long-terme',
        name: 'long-terme',
        component: () => import('@/views/strategy/LongTermeStrategy.vue')
      },
    ]
  }
]