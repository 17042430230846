import axios from './axios';

export default {
  get: () => axios.get('/form_answer'),
  enums: () => axios.get('/form_answer/enums'),
  update: (params) => axios.put('/form_answer', {
    form_answer: params,
  }),
  dashboard: () => axios.get('/form_answer/dashboard'),
};
