import Vue from 'vue'
import VueAxios from 'vue-axios';
import axios from './backend/axios';
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueApexCharts from 'vue-apexcharts'
import HighchartsVue from 'highcharts-vue'

Vue.config.productionTip = false

Vue.use(VueAxios, axios);
Vue.use(VueApexCharts);
Vue.use(HighchartsVue);

Vue.axios.interceptors.response.use((response) => {
  store.commit('setAlert', { show: false });
  return response;
}, (error) => {
  if (!error.response || error.response.status === 401) {
    delete localStorage.authorization;
    if (!router.currentRoute.matched.some((record) => record.meta.public)) {
      router.push('/login', {
        query: {
          nextUrl: router.currentRoute.path,
        },
      });
    }

    if (error.message === 'Network Error') {
      store.commit('setAlert', { show: true, text: 'Le serveur est indisponible', type: 'error' });
    } else {
      store.commit('setAlert', { show: false });
    }
  }

  if (error.response?.status === 500) {
    store.commit('setAlert', { show: true, text: 'Une erreur s\'est produite', type: 'error' });
  }
  return Promise.reject(error);
});

Vue.component('apexchart', VueApexCharts)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
