import { filter, find, findIndex, flatten, map, values } from 'lodash';
import { getField, updateField } from 'vuex-map-fields';
import ApplicationApi from '../../backend/ApplicationApi';
import DatacenterApi from '../../backend/DatacenterApi';
import EntiteApi from '../../backend/EntiteApi';
import EsrApi from '../../backend/EsrApi';
import FormAnswerApi from '../../backend/FormAnswerApi';
import FormAnswerTypeConnectiviteApi from '../../backend/FormAnswerTypeConnectiviteApi';
import ItOperateurApi from '../../backend/ItOperateurApi';
import LiaisonApi from '../../backend/LiaisonApi';
import LocalItApi from '../../backend/LocalItApi';
import LocalItEntiteHebergeeApi from '../../backend/LocalItEntiteHebergeeApi';
import LocalItItOperateurApi from '../../backend/LocalItItOperateurApi';
import NouveauServiceAttenduApi from '../../backend/NouveauServiceAttenduApi';
import OperateurApi from '../../backend/OperateurApi';
import PerimetreTravauxApi from '../../backend/PerimetreTravauxApi';
import ServiceDatacenterApi from '../../backend/ServiceDatacenterApi';
import ServiceHebergementApi from '../../backend/ServiceHebergementApi';
import SiteApi from '../../backend/SiteApi';
import TravauxApi from '../../backend/TravauxApi';
import TypeConnectiviteApi from '../../backend/TypeConnectiviteApi';
import TypeOrganisationApi from '../../backend/TypeOrganisationApi';
import { assignHelper } from '../helpers';

const initialState = () => ({
  entite: {
    impact_equivalent: {
      ramette_papier: 0,
      kms_voiture: 0,
      avion_paris_new_york: 0,
    },
    impact_equivalent_cible: {
      ramette_papier: 0,
      kms_voiture: 0,
      avion_paris_new_york: 0,
    },
    type_organisation: {},
    type_organisation_id: null,
    site_nom_lieu: '',
    site_adresse: '',
    secteur_activite: '',
    nombre_postes_de_travail: null,
    nombre_utilisateurs: null,
    nombre_utilisateurs_internet_externe: null,
    pourcentage_etp_dedie_exploitation_infrastructure: null,
    nombre_collaborateurs: null,
    nombre_total_etp: null,
    dependance_directe_id: null,
    commentaire: '',
    nombre_etudiants_inscrits: null,
    esr_id: null,
    prix_moyen_electricite_tous_sites_ht: false,
    prix_moyen_electricite_tous_sites: null,
    service_hebergements: [],
    nombre_etp_service_patrimoine: null,
    cout_hebergement_actuel_avec_maintien: null,
    cout_datacenter_cible: null,
    gain_datacenter_cible_pourcentage: null,
    pue: {
      value: null,
      flag_donnee_manquante: null,
    },
    maintien_en_condition_operationnelle: 0,
    datacenter: {
      nom: '',
    },
  },
  esr: {
    entite_ids: [],
    commentaire: '',
  },
  entites: [],
  esrEntites: [],
  type_organisations: [],
  operateurs: [],
  secteur_activites: [],
  formAnswer: {
    ressources_presence_cloud: null,
    ressources_pourcentage_cloud: '',
    ressources_presence_paas: null,
    ressources_pourcentage_paas: '',
    ressources_presence_saas: null,
    ressources_pourcentage_saas: '',
    ressources_presence_hybride: null,
    ressources_pourcentage_hybride: '',
    ressources_presence_propre: null,
    ressources_pourcentage_propre: '',
    preference_cout_moyen_etp: null,
    cout_moyen_etp: null,
    cout_etp_igr: null,
    cout_etp_ige: null,
    cout_etp_tch: null,
    cout_interruption_service: null,
    interruption_service_1_an: null,
    interruption_service_5_ans: null,
    interruption_service_10_ans: null,
    commentaire_ratios_interne: '',
    nouveau_service_attendus: [],
    strategie_long_terme_transformation_offres_choice: null,
    strategie_long_terme_transformation_delivery_model_choice: null,
    strategie_long_terme_detail_transformation_offre: '',
    strategie_long_terme_detail_transformation_delivery_model: '',
    strategie_long_terme_impact_capacites_ressources: '',
    strategie_long_terme_impact_besoin_hebergement: '',
    strategie_long_terme_impact_offre_hebergement: '',
    commentaire_strategie: '',
    personnel_entite: null,
    cout_etp_grade_c: null,
    nombre_personne_impactees: null,
    cout_etp_igr_pourcentage: null,
    cout_etp_ige_pourcentage: null,
    cout_etp_tch_pourcentage: null,
    cout_etp_grade_c_pourcentage: null,
    nombre_mesures_ansii_reco: null,
    delai_garantie_retablissement: null,
    plan_reprise_continuite_activite_ok: null,
    plan_reprise_continuite_activite_rto: null,
    plan_reprise_continuite_activite_rpo: null,
    infra_hebergement_nombre_serveurs_physiques: null,
    infra_hebergement_nombre_vm: null,
    infra_hebergement_nombre_applications: null,
    infra_hebergement_nombre_serveurs_dedies_bdd: null,
    pourcentage_volume_infra_totales: null,
    prix_electricite_par_campus_ok: true,
    rgpd_presence_dpo_dpd: null,
    rgpd_renseignement_registre_traitement: null,
    rgpd_renseignement_registre_sous_traitance: null,
    nombre_heures_interruption_derniere_annee: null,
    nombre_heures_interruption_trois_derniere_annee: null,
    besoin_capacite_hebergement_ok: null,
    besoin_fiabilite_hebergement_ok: null,
    besoin_securite_hebergement_ok: null,
    besoin_services_hebergement_ok: null,
    besoin_delai_mise_a_disposition_ok: null,
    cout_moyen_etp_jours_calendaires: null,
    connaissance_cout_liaisons_datacenter_ok: null,
    cout_liaisons_datacenter: null,
    longueur_liaison_fon_km: null,
    nombre_liaisons: null,
    dispo_informatique_choice: null,
    salle_serveurs_redondance_complete_choice: null,
    salle_serveurs_redondance_services_critiques_choice: null,
    sauvegarde_SI: null,
  },
  nouveau_service_attendus_liste: [],
  localIts: [],
  localItEnums: {
    alimentation_list: [],
    type_proprietaire_list: [],
    types: [],
    contrat_list: [],
    fiab_list: [],
    niveau_service_list: [],
  },
  formAnswerEnums: {
    debits: [],
    complexe_choice_options: [],
    simple_choice_options: [],
    extended_choice_options: [],
  },
  sites: [],
  typeLocalIts: [],
  typeConnectivites: [],
  dashboard: {
    profil: {
      personne: '',
      entite: '',
      ratios_internes: '',
      complement: '',
      percentage: 0,
    },
    ressources: {
      appreciation_hebergement_actuel: '',
      hebergement: '',
      percentage: 0,
    },
    besoins: {
      percentage: 0,
    },
    strategie: {
      percentage: 0,
    },
  },
  serviceDatacenters: [],
  applications: [],
  applicationEnums: {
    perimetres: [],
    type_hebergements: [],
  },
  perimetre_travaux: [],
  datacenterPresentationVideoUrl: '',
  datacenterPresentationPdfUrl: '',
  esrs: [],
  typeHebergementsServices: [],
  entitesCreatedByFront: [],
  resultDataAnnuel: {
    couts_recurrents: null,
    amortissements: null,
    cout_total_hebergement: null,
    energie: null,
    eau_fioul: null,
    reseau: null,
    gardiennage_et_entretien: null,
    cout_maintenance: null,
    impact_environnemental: null,
    nombre_baies: null,
    puissance_actuelle: null,
    niveau_de_risque: null,
    nombre_locaux_its: null,
    loyer_hebergement: null,
    exploitation_et_maintenance: null,
    gestes_de_proximite: null,
    services_managees: null,
    cout_cloud: null,
    recurrent_foncier: null,
    amortissement_foncier: null,
    amortissement_travaux: null,
    surface_totale_calculee: null,
    nombre_baies_calculees_total_utilise: null,
    puissance_consommee: null,
  },
  resultDataParBaie: {
    couts_recurrents: null,
    amortissements: null,
    cout_total_hebergement: null,
    energie: null,
    eau_fioul: null,
    reseau: null,
    gardiennage_et_entretien: null,
    cout_maintenance: null,
    loyer_hebergement: null,
    exploitation_et_maintenance: null,
    gestes_de_proximite: null,
    services_managees: null,
    cout_cloud: null,
    recurrent_foncier: null,
    amortissement_foncier: null,
    amortissement_travaux: null,
  },
  resultDataParEtudiant: {
    couts_recurrents: null,
    amortissements: null,
    cout_total_hebergement: null,
    energie: null,
    eau_fioul: null,
    reseau: null,
    gardiennage_et_entretien: null,
    cout_maintenance: null,
    loyer_hebergement: null,
    exploitation_et_maintenance: null,
    gestes_de_proximite: null,
    services_managees: null,
    cout_cloud: null,
    recurrent_foncier: null,
    amortissement_foncier: null,
    amortissement_travaux: null,
  },
  listServiceHebergements: [],
  datacenter: {},
  liaisons: null,
  fullSummaryDocUrl: '',
  simplifiedSummaryDocUrl: '',
  digestSummaryDocUrl: '',
});

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    resetStore: (state) => {
      Object.assign(state, initialState());
    },
    setEntite: assignHelper('entite'),
    setDatacenter: assignHelper('datacenter'),
    setEsr: assignHelper('esr'),
    setEsrs: assignHelper('esrs'),
    setEntites: assignHelper('entites'),
    setTypeOrganisations: assignHelper('type_organisations'),
    setSecteurActivites: assignHelper('secteur_activites'),
    setFormAnswer: assignHelper('formAnswer'),
    setNouveauServiceAttendusEnum: assignHelper(
      'nouveau_service_attendus_liste',
    ),
    setLocalIts: assignHelper('localIts'),
    setSites: assignHelper('sites'),
    setTypeLocalIts: assignHelper('typeLocalIts'),
    setEsrEntites: assignHelper('esrEntites'),
    setLocalItEnums: assignHelper('localItEnums'),
    setApplicationEnums: assignHelper('applicationEnums'),
    setFormAnswerEnums: assignHelper('formAnswerEnums'),
    setTypeHebergementsServices: assignHelper('typeHebergementsServices'),
    setOperateurs: assignHelper('operateurs'),
    setTypeConnectivites: assignHelper('typeConnectivites'),
    setDashboard: assignHelper('dashboard'),
    setServiceDatacenters: assignHelper('serviceDatacenters'),
    setApplications: assignHelper('applications'),
    setDatacenterVideoUrl: assignHelper('datacenterPresentationVideoUrl'),
    setDatacenterPdfUrl: assignHelper('datacenterPresentationPdfUrl'),
    setFullSummaryDocUrl: assignHelper('fullSummaryDocUrl'),
    setSimplifiedSummaryDocUrl: assignHelper('simplifiedSummaryDocUrl'),
    setDigestSummaryDocUrl: assignHelper('digestSummaryDocUrl'),
    setListServiceHebergements: assignHelper('listServiceHebergements'),
    addLocalIts(state, newLocaLIt) {
      state.localIts.push(newLocaLIt);
    },
    addApplication(state, newApplication) {
      state.applications.push(newApplication);
    },
    deleteLocalIt(state, oldLocalit) {
      const index = findIndex(state.localIts, { id: oldLocalit.id });
      state.localIts.splice(index, 1);
    },
    updateLocalIt(state, localIt) {
      const index = findIndex(state.localIts, { id: localIt.id });
      state.localIts.splice(index, 1, localIt);
    },
    updateApplication(state, application) {
      const index = findIndex(state.applications, { id: application.id });
      state.applications.splice(index, 1, application);
    },
    updateLocalItItOperateur(state, { localItId, localItItOperateur }) {
      const localIt = find(state.localIts, { id: localItId });
      const index = findIndex(localIt.local_it_it_operateurs, {
        id: localItItOperateur.id,
      });
      localIt.local_it_it_operateurs.splice(index, 1, localItItOperateur);
    },
    updateLocalItEntiteeHebergee(state, { localItId, localItEntiteHebergee }) {
      const localIt = find(state.localIts, { id: localItId });
      const index = findIndex(localIt.local_it_entite_hebergees, {
        id: localItEntiteHebergee,
      });
      localIt.local_it_entite_hebergees.splice(index, 1, localItEntiteHebergee);
    },
    addSites(state, newSite) {
      state.sites.push(newSite);
    },
    updateSite(state, site) {
      const index = findIndex(state.sites, { id: site.id });
      state.sites.splice(index, 1, site);
    },
    deleteSite(state, oldSite) {
      const index = findIndex(state.sites, { id: oldSite.id });
      state.sites.splice(index, 1);
    },
    updateField,
    setPerimetreTravaux: assignHelper('perimetre_travaux'),
    deleteApplication(state, oldApplicationId) {
      const index = findIndex(state.applications, { id: oldApplicationId });
      state.applications.splice(index, 1);
    },
    addTravaux(state, localItId, newTravaux) {
      if (state.localIts[localItId].projet_travaux.length) {
        state.localIts[localItId].projet_travaux.push(newTravaux);
      } else {
        state.localIts[localItId].projet_travaux = [newTravaux];
      }
    },
    addEntiteCreatedByFront(state, newEntiteCreatedByFront) {
      if (state.entitesCreatedByFront.length) {
        state.entitesCreatedByFront.push(newEntiteCreatedByFront);
      } else {
        state.entitesCreatedByFront = [newEntiteCreatedByFront];
      }
      if (state.esrEntites.length) {
        state.esrEntites.push(newEntiteCreatedByFront);
      } else {
        state.esrEntites = [newEntiteCreatedByFront];
      }
    },
    setEntiteCreatedByFront: assignHelper('entitesCreatedByFront'),
    deleteEntite(state, oldEntite) {
      const index = findIndex(state.entitesCreatedByFront, {
        id: oldEntite.id,
      });
      state.entitesCreatedByFront.splice(index, 1);

      const indexEsrEntites = findIndex(state.esrEntites, { id: oldEntite.id });
      state.esrEntites.splice(indexEsrEntites, 1);
    },
    updateEntite(state, entite) {
      const indexAutre = findIndex(state.entitesCreatedByFront, {
        id: entite.id,
      });
      state.entitesCreatedByFront.splice(indexAutre, 1, entite);

      const indexEntite = findIndex(state.esrEntites, { id: entite.id });
      state.esrEntites.splice(indexEntite, 1, entite);
    },
    setResultDataAnnuel: assignHelper('resultDataAnnuel'),
    setResultDataParBaie: assignHelper('resultDataParBaie'),
    setResultDataParEtudiant: assignHelper('resultDataParEtudiant'),
    updateServiceHebergement(state, service) {
      const index = findIndex(state.listServiceHebergements, {
        id: service.id,
      });
      state.sites.splice(index, 1, service);
    },
    createServiceHebergement(state, serviceHebergement) {
      state.listServiceHebergements.push(serviceHebergement);
    },
    removeItOperateur(state, { localItId, itOperateur }) {
      const localIt = find(state.localIts, { id: localItId });
      const index = findIndex(localIt.local_it_it_operateurs, {
        id: itOperateur.id,
      });
      localIt.local_it_it_operateurs.splice(index, 1);
    },
    setLiaisons: assignHelper('liaisons'),
  },
  actions: {
    async fetchEntite({ commit }) {
      const response = await EntiteApi.get();
      commit('setEntite', response.data);
    },
    async fetchEntites({ commit }) {
      const response = await EntiteApi.entites();
      commit('setEntites', response.data);
    },
    async fetchEsrEntites({ commit }) {
      const response = await EntiteApi.esrList();
      commit('setEsrEntites', response.data);
    },
    async fetchSites({ commit }) {
      const response = await SiteApi.list();
      commit('setSites', response.data);
    },
    async fetchEsr({ commit }) {
      const response = await EsrApi.get();
      commit('setEsr', response.data);
    },
    async fetchEsrs({ commit }) {
      const response = await EsrApi.esrs();
      commit('setEsrs', response.data);
    },
    async fetchTypeOrganisations({ commit }) {
      const response = await TypeOrganisationApi.list();
      commit('setTypeOrganisations', response.data);
    },
    async fetchSecteurActivites({ commit }) {
      const response = await EntiteApi.secteurActivites();
      commit('setSecteurActivites', response.data);
    },
    async saveEntite({ commit, state, dispatch }) {
      const response = await EntiteApi.update(state.entite.id, state.entite);
      commit('setEntite', response.data);
      dispatch('fetchEntite');
      dispatch('fetchDashboard');
    },
    async saveEsr({ commit, state, dispatch }) {
      const response = await EsrApi.save(state.esr);
      commit('setEsr', response.data);
      dispatch('fetchDashboard');
    },
    async fetchFormAnswer({ commit }) {
      const response = await FormAnswerApi.get();
      commit('setFormAnswer', response.data);
    },
    async fetchDashboard({ commit }) {
      const response = await FormAnswerApi.dashboard();
      commit('setDashboard', response.data);
    },
    async saveFormAnswer({ commit, state, dispatch }) {
      const response = await FormAnswerApi.update(state.formAnswer);
      commit('setFormAnswer', response.data);
      dispatch('fetchDashboard');
    },
    async fetchNouveauServiceAttendus({ commit }) {
      const response = await NouveauServiceAttenduApi.nouveauServiceAttendus();
      commit('setNouveauServiceAttendusEnum', response.data);
    },
    async fetchLocalIts({ commit, dispatch }) {
      let response = await LocalItApi.list();
      if (response.data.length) {
        commit('setLocalIts', response.data);
      } else {
        dispatch('createLocalIt');
      }
      response = await LocalItApi.types();
      commit('setTypeLocalIts', response.data);
    },
    async createLocalIt({ commit }) {
      const response = await LocalItApi.create();
      commit('addLocalIts', response.data);
    },
    async deleteLocalIt({ commit, dispatch }, localItId) {
      await LocalItApi.delete(localItId);
      commit('deleteLocalIt', localItId);
      dispatch('fetchLocalIts');
    },
    async updateLocalIt({ commit, state, dispatch }, localItId) {
      const localIt = find(state.localIts, { id: localItId });
      const response = await LocalItApi.update(localIt.id, localIt);
      commit('updateLocalIt', response.data);
      dispatch('fetchDashboard');
    },
    async fetchLocalItsEnums({ commit }) {
      const response = await LocalItApi.enums();
      commit('setLocalItEnums', response.data);
    },
    async fetchFormAnswersEnums({ commit }) {
      const response = await FormAnswerApi.enums();
      commit('setFormAnswerEnums', response.data);
    },
    async fetchOperateurs({ commit }) {
      const response = await OperateurApi.list();
      commit('setOperateurs', response.data);
    },
    async fetchTypeConnectivites({ commit }) {
      const response = await TypeConnectiviteApi.list();
      commit('setTypeConnectivites', response.data);
    },
    async updateLocalItItOperateur(
      { commit, state },
      { localItId, localItItOperateurId },
    ) {
      const localIt = find(state.localIts, { id: localItId });
      const localItItOperateur = find(localIt.local_it_it_operateurs, {
        id: localItItOperateurId,
      });
      const response = await LocalItItOperateurApi.update(localItItOperateur);
      commit('updateLocalItItOperateur', {
        localItId,
        localItItOperateur: response.data,
      });
    },
    async updateLocalItEntiteHebergee(
      { commit, state },
      { localItId, localItEntiteHebergeeId },
    ) {
      const localIt = find(state.localIts, { id: localItId });
      const localItEntiteHebergee = find(localIt.local_it_entite_hebergees, {
        id: localItEntiteHebergeeId,
      });
      const response = await LocalItEntiteHebergeeApi.update(
        localItEntiteHebergee,
      );
      commit('updateLocalItEntiteeHebergee', {
        localItId,
        localItEntiteHebergee: response.data,
      });
    },
    async saveFormAnswerTypeConnectivite(
      { commit, state },
      formAnswerTypeConnectiviteId,
    ) {
      const object = find(state.formAnswer.form_answer_type_connectivites, {
        id: formAnswerTypeConnectiviteId,
      });
      const response = await FormAnswerTypeConnectiviteApi.update(object);
      commit('setFormAnswer', response.data);
    },
    async fetchServiceDatacenters({ commit }) {
      const response = await ServiceDatacenterApi.list();
      commit('setServiceDatacenters', response.data);
    },
    async fetchApplications({ commit, dispatch }) {
      const response = await ApplicationApi.list();
      if (!response.data.length) {
        dispatch('createApplication');
      } else {
        commit('setApplications', response.data);
      }
    },
    async updateApplication({ commit, state }, applicationId) {
      const application = find(state.applications, { id: applicationId });
      const response = await ApplicationApi.update(application.id, application);
      commit('updateApplication', response.data);
    },
    async fetchApplicationEnums({ commit }) {
      const response = await ApplicationApi.enums();
      commit('setApplicationEnums', response.data);
    },
    async createApplication({ commit }) {
      const response = await ApplicationApi.create();
      commit('addApplication', response.data);
    },
    async createSite({ commit }) {
      const response = await SiteApi.create();
      commit('addSites', response.data);
      return response.data;
    },
    async updateSite({ commit, dispatch }, site) {
      const response = await SiteApi.update(site.id, site);
      commit('updateSite', response.data);
      dispatch('fetchDashboard');
    },
    async deleteSite({ commit, dispatch }, siteId) {
      await SiteApi.delete(siteId);
      commit('deleteSite', siteId);
      dispatch('fetchSites');
    },
    async fetchPerimetreTravaux({ commit }) {
      const response = await PerimetreTravauxApi.list();
      commit('setPerimetreTravaux', response.data);
    },
    async updateTravaux({ dispatch }, chantier) {
      await TravauxApi.update(chantier.id, chantier);
      dispatch('fetchDashboard');
    },
    async removeTravaux(_, { travauxId, currentLocalIt }) {
      await TravauxApi.delete(travauxId);
      const currentLocalItTravaux = currentLocalIt.projet_travaux;
      const index = findIndex(currentLocalItTravaux, { id: travauxId });
      currentLocalItTravaux.splice(index, 1);
    },
    async createTravaux({ commit, dispatch }, localItId) {
      const response = await TravauxApi.create(localItId);
      dispatch('fetchLocalIts');
      commit('addTravaux', localItId, response.data);
    },
    async uploadListeServeursAction(_, { localItId, file }) {
      const formData = new FormData();
      formData.append('file', file);
      await LocalItApi.uploadServerList(localItId, formData);
    },
    async deleteApplication({ commit }, applicationId) {
      await ApplicationApi.delete(applicationId);
      commit('deleteApplication', applicationId);
    },
    updateNouveauServiceAttendus({ state, dispatch }, nouveauServiceAttendus) {
      const nouveau_service_attendus_object_list = [];
      nouveauServiceAttendus.forEach((element, index) => {
        const stateElement = state.formAnswer.nouveau_service_attendus[index];
        nouveau_service_attendus_object_list.push({
          nom: element,
          nom_autre: stateElement
            ? state.formAnswer.nouveau_service_attendus[index].nom_autre
            : '',
          rang_nouveau_service_attendu: index + 1,
        });
      });
      state.formAnswer.nouveau_service_attendus = nouveau_service_attendus_object_list;
      dispatch('saveFormAnswer');
    },
    deleteCurrentOtherService({ state, dispatch }, currentOtherService) {
      const index = findIndex(state.formAnswer.nouveau_service_attendus, {
        id: currentOtherService.id,
      });
      state.formAnswer.nouveau_service_attendus.splice(index, 1);
      dispatch('saveFormAnswer');
    },
    addOtherService({ state, dispatch }) {
      state.formAnswer.nouveau_service_attendus.push({
        nom: 'Autres services',
        nom_autre: '',
      });
      dispatch('saveFormAnswer');
    },
    async fetchVideoPresentationDatacenterUrl({ commit }, datacenterId) {
      const response = await DatacenterApi.videoPresentationDatacenter(
        datacenterId,
      );
      commit('setDatacenterVideoUrl', response.data);
    },
    async fetchPdfPresentationDatacenterUrl({ commit }, datacenterId) {
      const response = await DatacenterApi.pdfPresentationDatacenter(
        datacenterId,
      );
      commit('setDatacenterPdfUrl', response.data);
    },
    async fetchTypeHebergementsServices({ commit }) {
      const response = await ServiceHebergementApi.listTypeHebergement();
      commit('setTypeHebergementsServices', response.data);
    },
    async fetchEntitesCreatedByFront({ commit }) {
      const response = await EntiteApi.entitesCreatedByFront();
      commit('setEntiteCreatedByFront', response.data);
    },
    async createEntiteCreatedByFront({ commit, dispatch }) {
      const response = await EntiteApi.createEntitesCreatedByFront();
      commit('addEntiteCreatedByFront', response.data);
      dispatch('fetchEntitesCreatedByFront');
    },
    async deleteEntite({ commit }, entiteId) {
      await EntiteApi.delete(entiteId);
      commit('deleteEntite', entiteId);
    },
    async updateEntiteCreatedByFront({ commit, state, dispatch }, entiteId) {
      const entite = find(state.entitesCreatedByFront, { id: entiteId });
      const response = await EntiteApi.update(entite.id, entite);
      commit('updateEntite', response.data);
      dispatch('fetchEntitesCreatedByFront');
      dispatch('fetchEsrEntites');
    },
    async fetchResultDataAnnuel({ commit }) {
      const response = await EntiteApi.resultDataAnnuel();
      commit('setResultDataAnnuel', response.data);
    },
    async fetchResultDataParBaie({ commit }) {
      const response = await EntiteApi.resultDataParBaie();
      commit('setResultDataParBaie', response.data);
    },
    async fetchResultDataParEtudiant({ commit }) {
      const response = await EntiteApi.resultDataParEtudiant();
      commit('setResultDataParEtudiant', response.data);
    },
    async fetchListServiceHebergements({ commit }) {
      const response = await ServiceHebergementApi.list();
      commit('setListServiceHebergements', response.data);
    },
    async updateServiceHebergement({ commit, state }, service_hebergement_id) {
      const payload = find(state.listServiceHebergements, {
        id: service_hebergement_id,
      });
      const response = await ServiceHebergementApi.update(
        service_hebergement_id,
        payload,
      );
      commit('updateServiceHebergement', response.data);
    },
    async createServiceHebergement({ commit }) {
      const response = await ServiceHebergementApi.create();
      commit('createServiceHebergement', response.data);
    },
    async deleteServiceHebergement({ state }, service_hebergement_id) {
      await ServiceHebergementApi.delete(service_hebergement_id);
      const index = findIndex(state.listServiceHebergements, {
        id: service_hebergement_id,
      });
      state.listServiceHebergements.splice(index, 1);
    },
    async removeItOperateur(
      { commit },
      { localItId: localItId, itOperateur: itOperateur },
    ) {
      await ItOperateurApi.delete(itOperateur.it_operateur.id);
      commit('removeItOperateur', { localItId, itOperateur });
    },
    async createItOperateur({ dispatch }, localItId) {
      await LocalItApi.createCustomItOperateur(localItId);
      dispatch('fetchLocalIts');
    },
    async updateItOperateur({ dispatch }, { itOperateur: itOperateur }) {
      await ItOperateurApi.update(itOperateur.id, itOperateur);
      dispatch('fetchLocalIts');
    },
    async fetchDatacenter({ commit }) {
      const response = await DatacenterApi.get();
      commit('setDatacenter', response.data);
    },
    async fetchLiaisons({ commit }) {
      const response = await LiaisonApi.list();
      commit('setLiaisons', response.data);
    },
    async fetchFullSummaryDocUrl({ commit }) {
      const response = await EntiteApi.summaryDocFull();
      commit('setFullSummaryDocUrl', response.data);
      return response.data;
    },
    async fetchSimplifiedSummaryDocUrl({ commit }) {
      const response = await EntiteApi.summaryDocSimplified();
      commit('setSimplifiedSummaryDocUrl', response.data);
      return response.data;
    },
    async fetchDigestSummaryDocUrl({ commit }) {
      const response = await EntiteApi.summaryDocDigest();
      commit('setDigestSummaryDocUrl', response.data);
      return response.data;
    },
  },
  getters: {
    getField,
    formIncomplete({ dashboard }) {
      if (dashboard) {
        const valuesList = flatten(map(values(dashboard), values));
        return valuesList.some(
          (element) => element === 'Informations indispensables',
        );
      }
      return true;
    },
    itOperateursSelectList({ operateurs }) {
      return filter(operateurs, (operateur) => operateur.nom !== 'Autre');
    },
  },
};
