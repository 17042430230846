/**
 * Stepper module (Global & Vertical)
 */
import { find } from 'lodash';
import { assignHelper } from '../helpers';

const initialState = () => ({
    next: "",
    currentStepPath: 1,
    horizontalSteps: [{
            id: 1,
            path: "/profile/person",
            item: "01",
            name: "Profil",
            backEndName: "profil",
            progress: '10',        
            icon: "$stepOneWhite",
            verticalSteps: [{
                    id: 1,
                    stepItem: "A",
                    name: "Personne",
                    path: "profile/person"
                },
                {
                    id: 2,
                    stepItem: "B",
                    name: "Établissement",
                    path: "profile/entity"
                },
                {
                    id: 3,
                    stepItem: "C",
                    name: "Compléments",
                    path: "profile/specific-esr"
                },
                {
                    id: 4,
                    stepItem: "D",
                    name: "Ratios Internes",
                    path: "profile/intern-ratios"
                }
            ],
        },
        {
            id: 2,
            path: "/ressources/current-host",
            item: "02",
            name: "Ressources",
            backEndName: "ressources",
            progress: '20',        
            icon: "$stepTwoWhite",
            verticalSteps: [{
                    id: 1,
                    stepItem: "A",
                    name: "Appréciation de votre hébergement actuel",
                    path: "ressources/current-host"
                },
                {
                    id: 2,
                    stepItem: "B",
                    name: "Vos différents types d'hébergement",
                    path: "ressources/host"
                },
                {
                    id: 4,
                    stepItem: "C",
                    name: "Vos ressources d'hébergement",
                    path: "ressources/site-definition"
                }
            ],
        },
        {
            id: 3,
            path: "/needs/security-referential",
            item: "03",
            name: "Besoins",
            backEndName: "besoins",
            progress: '30',        
            icon: "$stepThreeWhite",
            verticalSteps: [{
                    id: 1,
                    stepItem: "A",
                    name: "Référentiels de sécurité",
                    path: "needs/security-referential"
                },
                {
                    id: 2,
                    stepItem: "B",
                    name: "Votre besoin d'hébergement global",
                    path: "needs/macro-view"
                },
                /*
                {
                    id: 3,
                    stepItem: "C",
                    name: "Votre besoin d'hébergement par fonction applicative",
                    path: "needs/applicative-function"
                },
                */
                {
                    id: 4,
                    stepItem: "C",
                    name: "Le Datacenter mutualisé",
                    path: "needs/mutualized-datacenter"
                },
            ],
        },
        {
            id: 4,
            path: "/strategy/long-terme",
            item: "04",
            name: "Stratégie",
            backEndName: "strategie",
            progress: '40',
            icon: "$stepFourWhite",      
            verticalSteps: [{
                    id: 1,
                    stepItem: "A",
                    name: "Stratégie à 5 ans",
                    path: "strategy/long-terme"
                },
                {
                    id: 2,
                    stepItem: "B",
                    name: "Impact sur l'hébergement",
                    path: "strategy/impact"
                },
            ],
        },
        /*
        {
            id: 5,
            path:"/results",
            item: "05",
            name: "Résultats",
            icon: "$stepFiveWhite",
            verticalSteps: [],
        },
        */
    ],
});

export default {
    namespaced: true,
    state: initialState(),
    getters: {
        currentStep: ({ currentStepPath, horizontalSteps }) => {
            return find(horizontalSteps, { path: currentStepPath }) || horizontalSteps[0];
        },
        nextView: state => {
            return state.next;
        }
    },
    mutations: {
        resetStore: (state) => {
            Object.assign(state, initialState());
        },      
        setCurrentStepPath: assignHelper('currentStepPath'),
        setNextView(state, link) {
            state.next = link;
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        }
    },
};