import axios from './axios';

export default {
  updateMe: (params) => axios.put('/user', {
    user: params,
  }),
  updateWithPassword: ({ password, currentPassword }) => axios.put('/user/update_with_password', {
    user: {
      password,
      current_password: currentPassword,
    },
  }),
};
