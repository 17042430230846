import axios from './axios';

export default {
  signIn: (credentials) => axios.post('/users/sign_in', { user: credentials }),
  signOut: () => axios.delete('/users/sign_out'),
  me: () => axios.get('/me'),
  requestPasswordReset({ email }) {
    return axios.post('/users/password', { user: { email } });
  },
  updatePassword({ token, password }) {
    return axios.patch('/users/password', {
      user: {
        reset_password_token: token,
        password,
      },
    });
  },
};
