<template>
  <v-app id="app">
    <v-main>
      <v-container 
        fluid 
        class="pa-0"
      >
        <component :is="layout">
          <router-view />
        </component>
      </v-container>
    </v-main>
    <v-snackbar
      v-model="show"
      :timeout="3000"
      bottom
      color="cassiopee-red"
      elevation="24"
      outlined
    >
      {{ text }}
    </v-snackbar>
  </v-app>
</template>

<script>
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')
const LayoutDefault = () => import('@/layouts/default/LayoutDefault.vue')
const LayoutWrapper = () => import('@/layouts/wrapper/LayoutWrapper.vue')
import { mapFields } from 'vuex-map-fields';
import '@/assets/fonts.scss';
import '@/assets/materialdesignicons.css';

export default {
  name: 'App',
  components: {
    LayoutFull,
    LayoutDefault,
    LayoutWrapper
  },
  computed: {
    layout() {
      if (!this.$route.meta.layout) return 'layout-default'
      return `layout-${this.$route.meta.layout}`
    },
    ...mapFields(['alert.show', 'alert.text']),
  }
}
</script>

<style lang="scss">
#app {
  width: 100%;
  height: 100%;
}

.fill-height {
  height: 100%;
}

.flex-1 {
  flex: 1;
}
</style>
