/**
 * Concerns the Dashboard and Results Page.
 * - Access to Dashboard page: /dashboard
 * - Access to Results page: /results
 */
export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboardAndResults/Dashboard.vue'),
    meta: {
      layout: 'full'
    }
  },
  {
    path: '/results',
    name: 'results',
    component: () => import('@/views/dashboardAndResults/Results.vue'),
    meta: {
      layout: 'full'
    }
  }
]