/**
 * Concerns the Profile Page.
 * This route is working with NESTED routes
 * - Access to Person page: /profile/person
 * - Access to Entity page: /profile/entity
 * - Access to Intern-Ratios page: /profile/intern-ratios
 * - Access to Specifiic-esr page: /profile/specific-esr
 */
export default [
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/profile/TheProfile.vue'),
    meta: {
      layout: 'default',
    },
    children: [
      {
        path: 'person',
        name: 'person',
        component: () => import('@/views/profile/Person.vue'),
      },
      {
        path: 'entity',
        name: 'entity',
        component: () => import('@/views/profile/Entity.vue'),
      },
      {
        path: 'intern-ratios',
        name: 'intern-ratios',
        component: () => import('@/views/profile/InternRatios.vue'),
      },
      {
        path: 'specific-esr',
        name: 'specific-esr',
        component: () => import('@/views/profile/SpecificEsr.vue'),
      }
    ]
  },
]